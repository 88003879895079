<template>
    <el-container>
        <el-header>
            <HeaderTop />
        </el-header>
        <el-container>
            <el-aside width="200px">
                <LeftMenu />
            </el-aside>
            <el-container>
                <el-main>
                    <div class='list_content'>
                        <h2 style="text-align: center;">上传txt文件(不大于3MB)</h2>
                        <div style="padding:50px">
                            <el-upload class="upload-demo" drag action="https://shb.dreamsun.net/apis/upload/sha256" multiple :headers="uploaHeaders" :data="uploadData" :on-success="uploadSUcc">
                                <!-- <el-upload class="upload-demo" drag action="http://127.0.0.1:9090/apis/upload/sha256" multiple :headers="uploaHeaders" :data="uploadData" :on-success="uploadSUcc"> -->
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                <div class="el-upload__tip" slot="tip">只能上传小于3MB的txt文件</div>
                            </el-upload>
                        </div>

                        <div>
                            {{encodeList }}
                        </div>
                    </div>
                </el-main>
                <el-footer>
                    <Footer />
                </el-footer>
            </el-container>
        </el-container>
        <router-view /><!-- 路由出口 -->
    </el-container>
</template>

<script>
import LeftMenu from '@/components/LeftMenu0.vue'
import HeaderTop from '@/components/Top.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'UserAdd',
    // 注册组件
    components: {
        LeftMenu,
        HeaderTop,
        Footer
    },
    props: {},
    // data() 为变量赋值等
    data() {
        return {
            link: "",
            uploaHeaders: {
                // "Content-Type": "multipart/form-data"
            },
            uploadData: {
                // user_id: 0,
            },
            encodeList: "",
        }
    },

    created() { },

    methods: {
        uploadSUcc(response, file, fileList) {
            // alert(response.Msg)
            console.log(response)
            let encodeList = response.Data.EncodeList;
            let encodeContent = "";
            for (let i = 0; i < encodeList.length; i++) {
                console.log("encodeList : " + encodeList)
                console.log("encodeList[i] : " + encodeList[i])
                encodeContent += encodeList[i] + "\n";
            }
            this.encodeList = encodeContent
            this.download_txt("sha256-" + response.Data.FileName, encodeContent);
        },
        openLink() {
            window.open(this.link)
        },

        download_txt(filename, text) {
            var pom = document.createElement('a');
            pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            pom.setAttribute('download', filename);
            if (document.createEvent) {
                var event = document.createEvent('MouseEvents');
                event.initEvent('click', true, true);
                pom.dispatchEvent(event);
            } else {
                pom.click();
            }
        }
    }
}

</script>

<style scoped="scoped">
/*大框体样式*/
.el-main {
    height: calc(100vh - 120px);
}
.list_content {
    max-width: 800px;
}

.el-form-item__label {
    max-width: 200px !important;
}
.upload-demo {
    text-align: center;
}
</style>
